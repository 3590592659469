<template>
  <div class="cur-comment">
    <!-- 评论模块 提交一级评论 -->
    <div class="comment_model">
      <div class="my-comment">
        <div class="content">
          <div class="comment-bar">
            <img class="user-img" :src="get_cur_user().avatar_url" alt="" />
            <el-input
              class="us-input"
              type="textarea"
              placeholder="请输入想要评论的内容"
              v-model="comment_content"
              :autosize="{ minRows: 2, maxRows: 2 }"
              show-word-limit
              style="margin: 10px"
            >
            </el-input>
            <div v-if="cur_comment_content" class="comment-btn">
              <el-button type="primary" @click="submit_comment">评论</el-button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          'children' in cur_circle_data && cur_circle_data.children.length > 0
        "
        class="cus-comments"
      >
        <!-- 分割线 -->
        <div class="divider-line"></div>
        <!-- 子评论信息 一级评论 -->
        <div
          class="comments"
          v-for="(ele, index) in cur_circle_data.children"
          :key="index"
        >
          <!-- 每一个子评论 -->
          <div class="test">
            <div class="comment-item">
              <!-- 头像 -->
              <img class="commener_img" :src="ele.avatar_url" alt="" />
              <!-- 评论主体 -->
              <div class="comment-content">
                <div class="text">
                  <a class="commener">{{ ele.nick_name }}&nbsp;:&nbsp;</a>
                  {{ ele.content }}
                </div>
                <div class="comment-interactive">
                  <div class="left">
                    <span class="time">{{ ele.publish_time }}</span>
                  </div>
                  <div class="right">
                    <a
                      class="rate"
                      @click="click_reply_btn($event, { cur: ele }, index)"
                      ><i class="iconfont icon-pinglun1"></i>
                    </a>
                    <a
                      class="dianzan iconfont"
                      @click="like_btn_click($event, ele, 4)"
                      ><i class="iconfont" :class="like_btn_style(ele)"> </i>
                      &nbsp;<span v-if="ele.like_cnt > 0">{{
                        ele.like_cnt
                      }}</span></a
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- 二级及更多级评论 -->
            <div
              v-if="'children' in ele && ele.children.length > 0"
              class="comment-child"
              v-for="(elem, index2) in ele.children"
            >
              <!-- <div class="child-item"> -->
              <!-- 头像 -->
              <img class="commener_img" :src="elem.avatar_url" alt="" />
              <!-- 评论主体 -->
              <div class="comment-content">
                <div class="text">
                  <a class="commener"
                    >{{ elem.nick_name }}
                    <span v-if="elem.is_other_reply">
                      &nbsp;&nbsp;<span class="statis-text">{{
                        "回复" + ""
                      }}</span>
                      &nbsp;&nbsp;{{ elem.parent_nick_name }}</span
                    >&nbsp;:&nbsp;</a
                  >
                  {{ elem.content }}
                </div>
                <div class="comment-interactive">
                  <div class="left">
                    <span class="time">{{ elem.publish_time }}</span>
                  </div>
                  <div class="right">
                    <a
                      class="rate"
                      @click="
                        click_reply_btn($event, { cur: elem, par: ele }, index)
                      "
                      ><i class="iconfont icon-pinglun1"></i>
                    </a>
                    <a
                      class="dianzan iconfont"
                      @click="like_btn_click($event, elem, 4)"
                      ><i class="iconfont" :class="like_btn_style(elem)"> </i>
                      &nbsp;<span v-if="elem.like_cnt > 0">{{
                        elem.like_cnt
                      }}</span></a
                    >
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </div>
            <a
              href=""
              v-if="ele.child_comment_cnt > 0"
              class="more-reply"
              @click="view_replay($event, ele)"
              ><span>还有{{ ele.child_comment_cnt }}条回复</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { getUserInfo } from "../common/common.js";
export default {
  data() {
    return {
      // cur_circle_data: [],
      comment_content: "",
    };
  },
  props: ["target_id"],
  methods: {
    get_cur_user() {
      // console.log("ddsgdg : ", getUserInfo(), this.cur_circle_data);
      return getUserInfo();
    },

    like_btn_style(item) {
      // console.log("执行计算属性:style: ", item.is_like);
      if (item.is_like) {
        return "icon-dianzan1";
      } else {
        return "icon-dianzan";
      }
    },
    // 提交并清空评论内容
    async submit_comment(e) {
      if (this.cur_comment_content != "") {
        // console.log("当前的curcircle: ", this.cur_circle_data);
        const data = {
          content: this.cur_comment_content,
          rate_type: "comment",
          target_id: this.target_id,
          nick_name: this.get_cur_user().nick_name,
          parent_id: 0,
        };

        // console.log("data对象: ", data);
        try {
          var response = await this.$http.put(
            "http://www.soutudashi.com/add_comment",
            {
              headers: { "Content-Type": "application/json" },
              data,
              // data,
            }
          );

          // if (response.data.main.length == 0) {
          //   this.message_box("error", "该用户还没有在本站注册，请先注册");
          // } else {
          // console.log("评论接口返回结果response: friend ", response);

          // console.log("dddddddddsdfhdfhdf: ", response.data);

          this.$emit("add_reply", response.data);

          const res = response.data;
          // console.log("dddddd: ", this.get_cur_user);
          if (
            this.cur_circle_data != undefined &&
            this.cur_circle_data != null
          ) {
            // console.log("进入判断界面了");
            if (!("children" in this.cur_circle_data)) {
              this.$set(this.cur_circle_data, "children", []);
            }
            this.cur_circle_data.children.unshift({
              comment_id: res.comment_id,
              target_id: res.target_id,
              content: res.content,
              publish_time: res.publish_time,
              nick_name: res.nick_name,
              avatar_url: res.avatar_url,
              child_comment_cnt: res.child_comment_cnt,
              like_cnt: res.like_cnt,
              is_like: res.is_like,
            });
          }
          return;
          // }
        } catch (e) {
          console.log(e);
        } finally {
          this.comment_content = "";
        }
      }
    },
  },

  computed: {
    cur_circle_data: {
      get() {
        // console.log("触发计算属性,", this.cur_user_obj);
        return this.cur_user_obj == undefined ? [] : this.cur_user_obj;
      },
      set(newValue, oldValue) {
        // console.log("触发set: ", newValue, oldValue);
        this.cur_circle_data = newValue;
        // console.log("触发计算属性set,", this.cur_circle_data);
      },
    },
    cur_comment_content() {
      // console.log("res: ", this.cur_circle_data);
      return this.comment_content.trim();
    },
  },

  mounted() {
    console.log("接收得参数：", this.target_id);
  },
};
</script>

<style scoped>
.cur-comment > .comment_model > .my-comment > .content > .comment-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.cur-comment
  > .comment_model
  > .my-comment
  > .content
  > .comment-bar
  > .user-img {
  height: 60px;
}

.cur-comment
  > .comment_model
  > .my-comment
  > .content
  > .comment-bar
  > .el-input {
  line-height: 60px;
}
</style>
